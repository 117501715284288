import { AStageModule, EditStageViewProps, ModulesList } from "../../models";
import { SideInfo } from "./side-info";
import { Cmp, state } from ":mods";
import { createModule, deleteModules, getModulesList, updateModule } from "../../apis";
import { Match, Switch, batch, createEffect } from "solid-js";
import { STAGES } from "../../const";
import { useParams } from "@solidjs/router";
import { ModuleForm } from "./module-form";
import { getModuleRoute } from "../../methods";

export function EditStage({ $, stageID, ...props }: EditStageViewProps) {
  const params = useParams();
  stageID = stageID ?? params.id;
  const $modules_list = state.createAsync([getModulesList, stageID]);
  $.actions.setLayout({
    title: "Edit Modules for " + STAGES[stageID],
    sideInfo: [
      {
        title: "section info",
        element: () => <SideInfo />,
      },
    ],
  });

  function onCreate(values: AStageModule) {
    if (!values.objectives) values.objectives = null;
    // console.log("values are ", values);
    // return;
    createModule(stageID, values)
      .then((obj) => {
        console.log("obj is :: ", obj);
        $modules_list.set((s) => {
          return { ...s, data: [...s.data, { ...values, id: obj.id }] };
        });
      })
      .catch((res) => {
        console.log("res is :: ", res);
        const err = res.response.data.error;
        $.actions.pushToast({
          type: "error",
          message: typeof err === "string" ? err : err.join("\n"),
        });
      });
  }

  function onUpdate(values: AStageModule, index: number) {
    updateModule(values.id, values)
      .then(() => {
        $modules_list.set((s) => {
          const new_arr = [...s.data];
          new_arr[index] = { ...new_arr[index], ...values };
          // s.data = new_arr;
          console.log("updated module");
          return { ...s, data: new_arr };
        });
      })
      .catch((res) => {
        console.log("res is :: ", res);
        const err = res.response.data.error;
        $.actions.pushToast({
          type: "error",
          message: typeof err === "string" ? err : err.join("\n"),
        });
      });
  }

  function onDelete(indices: number[]) {
    const ids = indices.map((i) => $modules_list.value.data[i].id);
    const remove = [] as number[];
    deleteModules(ids).then((obj) => {
      obj.forEach((item) => {
        if (item.error) {
          $.actions.pushToast({
            type: "error",
            message: item.error,
          });
        }
        remove.push(item.id);
      });
      console.log("removed modules ", remove);
      if (remove.length > 0) {
        $modules_list.set((s) => ({ ...s, data: s.data.filter((x, i) => !remove.includes(x.id)) }));
        $.actions.pushToast({
          type: "success",
          message: "Deleted " + remove.length + " modules",
        });
      }
    });
  }

  async function onClickedItem(id: number) {
    let route: string = getModuleRoute(id, stageID);
    if (route) {
      $.actions.navigate(route);
    }
  }

  return (
    <Switch fallback={<div>unknown resource state </div>}>
      <Match when={$modules_list.state.loading}>Loading...</Match>
      <Match when={$modules_list.state.failed}>
        An error occured while fetching stage data {$modules_list.error} ...
      </Match>
      <Match when={$modules_list.state.success}>
        <main class="flex flex-col w-full h-full">
          {/* <div class="flex flex-row w-full h-fit">
            <p class="">state is {$state.value}</p>
          </div> */}
          <Cmp.List.Table
            rows={$modules_list.value.data}
            rowClass="w-full flex flex-row  text-20px"
            rowContainerClass="w-full flex flex-row rounded-lg px2 text-20px"
            colsClasses={{
              id: "!w-10% text-start capitalize",
              name: "!w-90% text-start capitalize ",
              // actions: "!w-30% capitalize",
            }}
            events={{
              onAllRows({ event, selected }) {
                console.log("action for all is ", event, selected);
                if (event === "delete") {
                  onDelete(selected);
                  // $modules_list.set((s) => ({ ...s, data: s.data.filter((x, i) => !selected.includes(i)) }));
                } else if (event === "create") {
                  // onCreate();
                  $.actions.pushModal({
                    title: "Create New Module",
                    element: (event) => (
                      <ModuleForm
                        stageID={stageID}
                        onDone={(values) => {
                          event("close");
                          onCreate(values as any);
                        }}
                      />
                    ),
                  });
                }
              },
              onSingleRow({ event, item, index, rows }) {
                if (event === "delete") {
                  onDelete([index]);
                  // rows = rows.filter((x) => x.id !== item.id);
                  // $modules_list.set((s) => ({ ...s, data: s.data.filter((x) => x.id !== item.id) }));
                } else if (event === "clicked") {
                  onClickedItem(item.id);
                } else if (event === "create") {
                  // onCreate();
                  $.actions.pushModal({
                    title: `Create New Module (Template of ${item.name})`,
                    element: (event) => (
                      <ModuleForm
                        stageID={stageID}
                        moduleID={item.id}
                        onDone={(values) => {
                          event("close");
                          onCreate(values as any);
                        }}
                      />
                    ),
                  });
                } else if (event === "edit") {
                  $.actions.pushModal({
                    title: `Update Module ${item.name}`,
                    element: (event) => (
                      <ModuleForm
                        stageID={stageID}
                        moduleID={item.id}
                        buttonMsg="Update"
                        onDone={(values) => {
                          event("close");
                          onUpdate(values as any, index);
                        }}
                      />
                    ),
                  });
                }
              },
            }}
          />
        </main>
      </Match>
    </Switch>
  );
}
